<template>
  <layout title="Feladataim">
    <template v-slot:header>
      <v-spacer />

      <div class="d-flex align-center flex-column">
        <h3 style="text-transform: capitalize;">{{ currentDay }}</h3>

        <v-subheader style="height: unset;">
          {{ currentDate }}
        </v-subheader>
      </div>

      <v-spacer />
    </template>

    <template v-slot:menu>
      <v-spacer />

      <div class="d-flex align-center justify-end">
        <v-menu min-width="auto" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>

          <v-date-picker
            v-model="currentDayForDatePicker"
            scrollable
            locale="hu-hu"
            first-day-of-week="1"
          />
        </v-menu>

        <v-btn text @click="setTodayAsCurrentDay">Ma</v-btn>
      </div>
    </template>

    <template v-slot:vertical_more>
      <v-menu offset-x left>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-icon class="mr-2">mdi-menu-left</v-icon>
            Nézet
          </v-list-item>
        </template>

        <v-list>
          <v-list-item @click="calendarView = 'daily-custom'">
            Napi nézet
          </v-list-item>

          <v-list-item @click="calendarView = 'weekly'">
            Heti nézet
          </v-list-item>

          <v-list-item @click="calendarView = 'monthly'">
            Havi nézet
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider />
    </template>

    <template v-slot:content>
      <daily-view
        v-if="calendarView == 'daily-custom'"
        :date.sync="now"
        ref="dailyView"
        @edit="openEditTaskDialog"
      />

      <calendar-view v-else :date.sync="now" ref="calendarView" :type="calendarView" />

      <v-btn
        fixed
        bottom
        right
        fab
        color="primary"
        @click="openEditTaskDialog()"
        data-testid="create-task"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <edit-task-dialog ref="editTaskDialog" />
    </template>
  </layout>
</template>

<script>
import { format, isToday, formatISO } from 'date-fns';
import { hu } from 'date-fns/locale';
import EditTaskDialog from '../components/dialog/EditTaskDialog.vue';
import Layout from '../layouts/Main.vue';
import DailyView from '../components/routine/DailyView.vue';
import CalendarView from '../components/routine/CalendarView.vue';

export default {
  components: {
    EditTaskDialog,
    Layout,
    DailyView,
    CalendarView,
  },

  data() {
    return {
      now: new Date(),
      calendarView: 'daily-custom',
    };
  },

  computed: {
    currentDate() {
      return format(this.now, this.calendarView === 'daily-custom' ? 'PPP' : 'yyyy. MMMM', { locale: hu });
    },

    currentDay() {
      return format(this.now, 'eeee', { locale: hu });
    },

    currentDayForDatePicker: {
      get() {
        return formatISO(this.now, { representation: 'date', locale: hu });
      },

      set(value) {
        this.now = new Date(value);
      },
    },
  },

  methods: {
    isCurrentDay(day) {
      return isToday(day);
    },

    setTodayAsCurrentDay() {
      this.now = new Date();
    },

    openEditTaskDialog(task) {
      const taskForEdit = task ? JSON.parse(JSON.stringify(task)) : undefined;

      this.$refs.editTaskDialog
        .open(taskForEdit, !task ? this.currentDayForDatePicker : undefined)
        .then(async (editedTask) => {
          await this.$store.dispatch('saveTask', editedTask);

          if (this.calendarView === 'daily-custom') {
            this.$refs.dailyView.list();
          } else {
            this.$refs.calendarView.list();
          }
        })
        .catch(() => {});
    },
  },
};
</script>
