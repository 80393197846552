var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"py-3"},[_c('v-btn',{staticClass:"ma-3",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ma-3",attrs:{"text":""}},on),[_vm._v("Dátum nélküli feladatok")])]}}])},[_c('v-card',{attrs:{"min-width":"350px","flat":""}},[_c('v-card-text',_vm._l((_vm.datelessTasks),function(task){return _c('task-card',{key:task.id,attrs:{"task":task,"draggable":"","data-testid":"dateless-task"},on:{"edit":function($event){return _vm.openEditTaskDialog(task)},"check-change":function($event){return _vm.handleTaskDone(task)},"change":_vm.list,"delete":function($event){return _vm.openDeleteTaskDialog(task)},"delete-checklist-item":function($event){return _vm.openDeleteChecklistItemDialog(task, $event)},"toggle-checklist-item-done":function($event){return _vm.$store.dispatch('toggleChecklistItemDone', {
                task,
                checklistItem: $event,
                date: _vm.currentDayForDatePicker
              })},"postpone":function($event){return _vm.handlePostponeTask(task)},"moveToWorkspace":function($event){return _vm.moveToWorkspace(task)}}})}),1)],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-3",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-calendar',{ref:"calendar",attrs:{"weekdays":[1, 2, 3, 4, 5, 6, 0],"type":_vm.type.replace('ly', ''),"events":_vm.events,"event-start":"startText","event-end":"endText","event-overlap-mode":"column","event-overlap-threshold":30},on:{"change":_vm.list,"click:event":_vm.showEvent},scopedSlots:_vm._u([{key:"event",fn:function({
        event,
        eventParsed: {
          start: { hour: startHour, minute: startMinute },
          end: { hour: endHour, minute: endMinute } }
      }){return [_c('div',{staticClass:"pa-1"},[(_vm.type == 'monthly' && event.timed)?_c('div',{staticStyle:{"color":"rgba(255, 255, 255, 0.7)"}},[_vm._v(" "+_vm._s(startHour)+":"+_vm._s(startMinute < 10 ? '0' : '')+_vm._s(startMinute)+" - "),_vm._v(" "+_vm._s(endHour)+":"+_vm._s(endMinute < 10 ? '0' : '')+_vm._s(endMinute)+" ")]):_vm._e(),_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","label":event.name},model:{value:(event.done),callback:function ($$v) {_vm.$set(event, "done", $$v)},expression:"event.done"}})],1)]}}]),model:{value:(_vm.currentDayForDatePicker),callback:function ($$v) {_vm.currentDayForDatePicker=$$v},expression:"currentDayForDatePicker"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.selectedEvent.name))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-heart")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.selectedEvent.description)+" ")]),_c('v-card-actions')],1)],1),_c('choose-workspace-dialog',{ref:"chooseWorkspaceDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }