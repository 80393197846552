<template>
  <v-dialog v-model="dialog" min-width="350px" width="60%">
    <v-card>
      <v-form ref="taskForm" @submit.prevent="save">
        <v-card-title data-testid="edit-task-header">
          Feladat {{ task.id ? 'szerkesztése' : 'létrehozása' }}
        </v-card-title>

        <v-card-text>
          <v-card class="pa-4 mb-2" outlined>
            <v-text-field
              label="Feladat címe"
              v-model="task.title"
              :rules="[v => !!v || 'Kötelező']"
              ref="taskTitleInput"
              data-testid="edit-task-title"
            />

            <v-text-field label="Feladat leírása" v-model="task.description" />
          </v-card>

          <v-card class="pa-4 mb-2" outlined>
            <v-checkbox
              label="Dátum megadása"
              v-model="showTimeInput"
              hide-details
              class="mt-0"
              data-testid="edit-task-date-checkbox"
            />

            <v-menu
              v-if="showTimeInput"
              v-model="taskDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="task.date"
                  label="Dátum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v || 'Kötelező']"
                  data-testid="edit-task-date-input"
                />
              </template>

              <v-date-picker
                v-model="task.date"
                @input="taskDateMenu = false"
                first-day-of-week="1"
                locale="hu-hu"
              />
            </v-menu>

            <v-checkbox
              v-if="task.date"
              label="Idősáv megadása"
              v-model="task.planned"
              hide-details
              class="mt-0"
              data-testid="edit-task-time-checkbox"
            />

            <v-row v-if="task.planned">
              <v-col>
                <v-menu
                  ref="startTimeMenu"
                  v-model="startTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="task.start"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="task.start"
                      placeholder="Kezdés időpontja"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || 'Kötelező']"
                      data-testid="edit-task-time-start"
                    />
                  </template>

                  <v-time-picker
                    v-if="startTimeMenu"
                    format="24hr"
                    v-model="task.start"
                    :max="task.end"
                    full-width
                    no-title
                    @click:minute="$refs.startTimeMenu.save(task.start)"
                  />
                </v-menu>
              </v-col>

              <v-col class="d-flex shrink align-center">
                <v-icon>mdi-arrow-right-thin</v-icon>
              </v-col>

              <v-col>
                <v-menu
                  ref="endTimeMenu"
                  v-model="endTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="task.end"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="task.end"
                      placeholder="Végzés időpontja"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || 'Kötelező']"
                      data-testid="edit-task-time-end"
                    />
                  </template>

                  <v-time-picker
                    v-if="endTimeMenu"
                    format="24hr"
                    v-model="task.end"
                    :min="task.start"
                    full-width
                    no-title
                    @click:minute="$refs.endTimeMenu.save(task.end)"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-card>

          <v-card outlined class="pa-4 mb-2" v-if="showTimeInput">
            <v-checkbox
              label="Ismétlődés beállítása"
              v-model="task.recurring"
              hide-details
              class="mt-0"
              data-testid="edit-task-recurring-checkbox"
            />

            <v-row v-if="task.recurring">
              <v-col>
                <v-text-field
                  v-model="task.recurrence_frequency"
                  type="number"
                  label="Ismétlődés gyakorisága"
                  :rules="[v => v > 0 || '0-nál nagyobb számot kell megadni']"
                  data-testid="edit-task-recurrence-frequency"
                />
              </v-col>

              <v-col>
                <v-select
                  label="Ismétlődés időszaka"
                  v-model="task.recurrence_interval"
                  :items="[
                    { text: 'Naponta', value: 'day' },
                    { text: 'Hetente', value: 'week' },
                    { text: 'Havonta', value: 'month' },
                    { text: 'Évente', value: 'year' },
                  ]"
                  data-testid="edit-task-recurrence-interval"
                />
              </v-col>

              <v-col
                cols="12"
                v-if="task.recurrence_interval == 'week'"
                class="d-flex"
                style="justify-content: space-around;"
              >
                <v-checkbox
                  v-for="(day, index) in [
                    {day: 'monday', label: 'Hé'},
                    {day: 'tuesday', label: 'Ke'},
                    {day: 'wednesday', label: 'Sze'},
                    {day: 'thursday', label: 'Csü'},
                    {day: 'friday', label: 'Pé'},
                    {day: 'saturday', label: 'Szo'},
                    {day: 'sunday', label: 'Va'},
                  ]"
                  :key="index"
                  v-model="task.recurring_days"
                  :value="day.day"
                  :label="day.label"
                  class="mr-2 mt-0"
                  hide-details
                />
              </v-col>

              <v-col
                cols="12"
                v-if="task.recurrence_interval == 'month' || task.recurrence_interval == 'year'"
              >
                <v-alert type="warning">
                  Az ismétlődés a fentebbi "Dátum" mezőhöz igazítva kerül beállításra!
                  Tehát pl. 1 havi rendszerességnél, ha a dátum 2022-01-01, akkor minden
                  hónap első napjára (ami 2022-01-01, vagy utána van) kerül be a feladat.
                  1 évi rendszerességnél pedig ha a dátum 2022-01-01, akkor a következő
                  esedékesség 2023-01-01 lesz.
                </v-alert>
              </v-col>
            </v-row>
          </v-card>

          <v-card outlined class="pa-4">
            <v-checkbox
              label="Teendők listájának hozzáadása"
              class="mt-0"
              hide-details
              v-model="task.has_checklist"
              data-testid="edit-task-todolist-checkbox"
            />
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text @click="close">Mégse</v-btn>

          <v-btn type="submit" color="primary" data-testid="edit-task-save-btn">Mentés</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      resolve: null,
      reject: null,
      dialog: false,
      taskDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      showTimeInput: false,
      currentDate: null,
      task: {},
    };
  },

  watch: {
    'task.date': function d() {
      if (this.task.date) {
        this.currentDate = this.task.date;
      }
    },

    showTimeInput() {
      if (this.showTimeInput) {
        this.task.date = this.currentDate;
      } else {
        this.task.date = null;
      }
    },
  },

  methods: {
    setTaskToDefaults() {
      this.showTimeInput = false;
      this.task = {
        title: '',
        description: '',
        done: false,
        recurring: false,
        recurrence_frequency: 1,
        recurrence_interval: 'day',
        recurring_days: [],
        planned: false,
        date: null,
        start: '',
        end: '',
        has_checklist: false,
        checklist: [],
      };
    },

    open(task, currentDate) {
      if (task) {
        this.task = task;
        this.showTimeInput = !!task.date;
      } else {
        this.setTaskToDefaults();
      }

      if (currentDate) {
        this.currentDate = currentDate;
      }

      this.dialog = true;

      if (!task) {
        setTimeout(() => {
          this.$refs.taskTitleInput.focus();
        }, 250);
      }

      try {
        this.$refs.taskForm.resetValidation();
      } catch (e) {
        // on first open
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    save() {
      if (this.$refs.taskForm.validate()) {
        this.dialog = false;
        this.resolve(this.task);
      }
    },

    close() {
      this.dialog = false;
      this.reject();
    },
  },
};
</script>
