<template>
  <div>
    <v-row class="py-3">
      <v-btn class="ma-3" icon @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-spacer />

      <v-menu
        :close-on-content-click="false"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text class="ma-3">Dátum nélküli feladatok</v-btn>
        </template>

        <v-card
          min-width="350px"
          flat
        >
          <v-card-text>
            <task-card
              v-for="task in datelessTasks"
              :key="task.id"
              :task="task"
              draggable
              @edit="openEditTaskDialog(task)"
              @check-change="handleTaskDone(task)"
              @change="list"
              @delete="openDeleteTaskDialog(task)"
              @delete-checklist-item="openDeleteChecklistItemDialog(task, $event)"
              @toggle-checklist-item-done="
                $store.dispatch('toggleChecklistItemDone', {
                  task,
                  checklistItem: $event,
                  date: currentDayForDatePicker
                })
              "
              @postpone="handlePostponeTask(task)"
              @moveToWorkspace="moveToWorkspace(task)"
              data-testid="dateless-task"
            />
          </v-card-text>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-btn class="ma-3" icon @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>

    <v-calendar
      ref="calendar"
      v-model="currentDayForDatePicker"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      :type="type.replace('ly', '')"
      :events="events"
      event-start="startText"
      event-end="endText"
      event-overlap-mode="column"
      :event-overlap-threshold="30"
      @change="list"
      @click:event="showEvent"
    >
      <template
        v-slot:event="{
          event,
          eventParsed: {
            start: { hour: startHour, minute: startMinute },
            end: { hour: endHour, minute: endMinute } }
        }"
      >
        <div class="pa-1">
          <div v-if="type == 'monthly' && event.timed" style="color: rgba(255, 255, 255, 0.7);">
            <!-- eslint-disable-next-line -->
            {{ startHour }}:{{ startMinute < 10 ? '0' : '' }}{{ startMinute }}
            -
            <!-- eslint-disable-next-line -->
            {{ endHour }}:{{ endMinute < 10 ? '0' : '' }}{{ endMinute }}
          </div>

          <v-checkbox v-model="event.done" hide-details class="mt-0" :label="event.name" />
        </div>
      </template>
    </v-calendar>

    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card
        color="grey lighten-4"
        min-width="350px"
        flat
      >
        <v-toolbar
          :color="selectedEvent.color"
          dark
        >
          <v-btn icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>

          <v-spacer />

          <v-btn icon>
            <v-icon>mdi-heart</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          {{ selectedEvent.description }}
        </v-card-text>

        <v-card-actions>
          <!-- <v-btn
            text
            color="secondary"
            @click="selectedOpen = false"
          >
            Cancel
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-menu>

    <choose-workspace-dialog ref="chooseWorkspaceDialog" />
  </div>
</template>

<script>
import {
  formatISO, format, startOfMonth, endOfMonth, startOfWeek, endOfWeek,
} from 'date-fns';
import { hu } from 'date-fns/locale';
import axios from 'axios';
import { mapState } from 'vuex';
import TaskCard from './TaskCard.vue';
import ChooseWorkspaceDialog from '../dialog/ChooseWorkspaceDialog.vue';

export default {
  components: { TaskCard, ChooseWorkspaceDialog },

  props: {
    date: {
      type: Date,
      required: true,
    },

    type: {
      type: String,
      required: true,
      validator: (value) => ['monthly', 'weekly'].includes(value),
    },
  },

  data() {
    return {
      events: [],
      selectedEvent: {},
      datelessTasks: [],
      selectedElement: null,
      selectedOpen: false,
    };
  },

  computed: {
    ...mapState('workspace', ['currentWorkspace']),

    currentDayForDatePicker: {
      get() {
        return formatISO(this.date, { representation: 'date', locale: hu });
      },

      set(value) {
        this.$emit('update:date', new Date(value));
      },
    },
  },

  watch: {
    currentWorkspace() {
      this.list();
    },
  },

  methods: {
    async list() {
      const start = this.type === 'monthly' ? startOfMonth(this.date) : startOfWeek(this.date);
      const end = this.type === 'monthly' ? endOfMonth(this.date) : endOfWeek(this.date);
      this.events = await axios.get(`/workspace/${this.$store.state.workspace.currentWorkspace.id}/tasks/period/start/${format(start, 'yyyy-MM-dd')}/end/${format(end, 'yyyy-MM-dd')}`).then((res) => res.data);
      this.datelessTasks = await axios.get(`/workspace/${this.$store.state.workspace.currentWorkspace.id}/tasks/no-date`).then((res) => res.data);
    },

    async moveToWorkspace(task) {
      try {
        const workspaceId = await this.$refs.choooseWorkspaceDialog.open();
        await this.moveTaskToWorkspace({ task, workspaceId });
        await this.list();
      } catch (e) {
        if (e !== undefined) {
          console.error(e);
        }
      }
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true; }));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style>
.v-event {
  height: unset !important;
}
</style>
